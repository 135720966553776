import { Container } from "reactstrap";
import Marquee from "react-fast-marquee";
import { Link , useNavigate } from "react-router-dom";
import { GetAdmissionList, GetNewsList } from "../../api/news-functions";
import globalVariables from "../../global-variables";
import { useState, useEffect } from "react";

export default function NewsTickerBlock() {

    // constant to be used on the page
    const [news_ticker, setNewsTickerList] = useState([]);

    // success function when the news is retrived
    const OnGetNewsSuccess = (p_response) => {
        // set the list for looping
        setNewsTickerList(p_response);
    }
    const navigate =  useNavigate();

    // things to be done when the page is loaded
    useEffect(() => {

        // make the object for getting the visual panel list
        let _objNews = new Object();
        
        // call the function to get the roadblock
        GetAdmissionList(_objNews, OnGetNewsSuccess,navigate);

    }, []);

    return (

        <>
         {/* check if the news ticker list is loaded then bind it */}
            {
                news_ticker.length > 0 ?

                    <div className="container-fluid bg-ticker p-3 px-0 w-100">
                        <Container>
                            <Marquee pauseOnHover="true" delay="1" speed="40">
                                <div className="d-flex">
                                    {
                                        news_ticker.map((val) => {

                                            return (
                                                <div className="px-4" key={val.news_id}>
                                                    <Link to={globalVariables.variables.strDomainUrl + "admissions"}>
                                                        <strong>{val.news_date}:</strong> {val.news_headline}
                                                    </Link>
                                                </div>
                                            )

                                        })

                                    }

                                </div>
                            </Marquee>
                        </Container>
                    </div>
                    :
                    null

            }
        </>


    )

}