import React, { useEffect, useState } from 'react';
import Header from "../layout/header";
import Peoplenav from '../layout/people';
import { Container, Row, Col, AccordionItem, AccordionHeader } from 'reactstrap';
import global from "../../global-variables";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsChevronRight } from "react-icons/bs";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import BgPeopleImg from '../../assets/default_img/bg-people.png';
import DefaultImg from '../../assets/default_img/people-default.jpg';

import { useLocation,useParams } from "react-router-dom";

import Metatag from "../../hooks/Metatag";

export default function FacultyInfo() {


    let {people_pagename , pcategory_pagename} = useParams();
    const qs_people_pagename = people_pagename;
    const qs_pcategory_pagename = pcategory_pagename;
    
    const [getpeoplelist, setPeopleList] = useState(null);

    const [getpeopleId, setPeopleId] = useState('');

    const [getpeoplereslist, setPeopleResList] = useState([]);

    const [show, setShow] = useState(false);
    const [getfacultyid, setFacultyid] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        let _obj = new Object();
        _obj.people_pagename = qs_people_pagename;
        _obj.pcategory_pagename = qs_pcategory_pagename;
        
        axios.post(global.variables.strApiUrl + '/peopleApi/getpeopleList', _obj)
            .then((response_people) => {

                if(response_people.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setPeopleList(response_people.data[0]);
                    setPeopleId(response_people.data[0][0]?.people_id);
                        

                }
            });

    }, [qs_pcategory_pagename,qs_people_pagename])
    
    useEffect(()=>{
        let _obj = new Object();
        _obj.people_id = getpeopleId
            //get research depened on people
            axios.post(global.variables.strApiUrl + '/peopleApi/getresearchpeopleList', _obj)
            .then((response_mappeopl_res) => {

                if(response_mappeopl_res.data === "error")
                {
                     navigate("/error-page")
                }
                else
                {
                    setPeopleResList(response_mappeopl_res.data[0]);
                }
            });
    },[getpeopleId])

    const handleClose = () => setShow(false);


    const [value, setValue] = React.useState(1);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        if (typeof window !== 'undefined')
            window.history.pushState(null, null, `#${newValue}`);
    };
    if (typeof window !== 'undefined') {
        window.addEventListener('popstate', () => {
            const params = new URLSearchParams(window.location.search)
            const newValue = params.get('tab')
            if (newValue) setValue(newValue)
        });
    };


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Header main_class="bg-header-home"></Header>
                {
                    getpeoplelist != null && getpeoplelist != undefined?
                        getpeoplelist[0]?.people_designation != "" && getpeoplelist[0]?.people_designation != null ?
                        <Metatag title={getpeoplelist[0]?.people_name  + " | " + getpeoplelist[0]?.people_designation} keywords={getpeoplelist[0]?.people_name + " | " + getpeoplelist[0]?.people_designation} description={getpeoplelist [0]?.people_name + " | " + getpeoplelist[0]?.people_designation}/>
                        :
                        <Metatag title={getpeoplelist[0]?.people_name} keywords={getpeoplelist[0]?.people_name} description={getpeoplelist [0]?.people_name}/>

                    :
                    null
                }
            <div className="container-fluid visual-container-1920 px-0">
                <div className="bg-visual-inside position-relative" style={{ backgroundImage: "url('../../images/masthead-people.jpg')" }}>
                    <img src={global.variables.strDomainUrl + "images/masthead-spacer.png"} className="img-fluid" alt="" title="" />
                    <Container className="visual-text mx-auto">
                        <h1 className="fw-semibold wow fadeInUp">People</h1>
                    </Container>
                </div>
            </div>
            {
            getpeoplelist != null ?
                <div className="container-fluid my-xl-5 my-4 px-0">
                    <Container className="px-md-5">
                        <Container>
                            <div className="border border-primary rounded-0 p-xl-5 p-3 admin-contanier">

                            {
                                getpeoplelist.map((val)=>{
                                    return(
                                        <Row key={val.people_id}>
                                            <Col md={4} lg={3} className='pe-xl-4 pe-md-3'>
                                                {
                                                    val.people_pic != null && val.people_pic != "" ?
                                                    <img src={global.variables.strApiUrl + "/people/" + val.people_pic} className="img-fluid border mb-2" alt={val.people_name} title={val.people_name} /> :
                                                    <img src={DefaultImg} className="img-fluid border mb-2" alt={val.people_name} title={val.people_name}/>

                                                }
                                            </Col>
                                            <Col md={8} lg={9} className='d-flex flex-column'>
                                                <h4 class="fw-semibold text-primary lh-sm">{val.people_name}</h4>
                                                <h6 class="fw-bold mb-3">{val.people_designation}</h6>

                                                {
                                                    val.people_research_inst != null && val.people_research_inst != "" ?
                                                    <p className='ck-content'><b>Research Interests:</b><span dangerouslySetInnerHTML={{ __html: val.people_research_inst }}></span></p> 
                                                    :null
                                                }
                                                
                                                {
                                                    val.people_publications != null && val.people_publications != "" ?
                                                        <p><b>Publications / Recognition:</b> {val.people_publications}</p> :
                                                        null
                                                }
                                                {/* get people related research */}
                                                {
                                                    getpeoplereslist.length > 0 ?
                                                    <>
                                                    <b>Research Areas:</b>
                                                    <ul>
                                                    {
                                                        getpeoplereslist.map((val)=>{
                                                            return(
                                                                <>
                                                                {
                                                                    <li> 
                                                                        <Link to={global.variables.strDomainUrl + "research-details/" + val.res_pagename}>{val.res_pro_name}</Link>
                                                                    </li>
                                                                }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                    </>
                                                :null
                                                
                                                }
                                                {
                                                    val.people_profile_brief != null && val.people_profile_brief != "" ?
                                                        <p className='ck-content'><span dangerouslySetInnerHTML={{ __html: val.people_profile_brief }}></span></p> :
                                                        null
                                                }
                                                <div className='d-flex justify-content-between pt-3 mt-auto'>

                                                    {
                                                        //browser link
                                                        val.people_url != "" && val.people_url != null ?
                                                        <Link class="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal" to={val.people_url} target="_blank">Read More</Link> :
                                                            null
                                                    }
                                                    <Link class="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal" onClick={() => navigate(-1)}>Back</Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                            
                                })
                            }
                            
                            </div>
                        </Container>
                    </Container>
                </div>
            :
                <div className="container-fluid px-0 my-md-5 my-4">
                    <Container >
                        <Row>
                            <span className='text-center'>Loading...</span>
                        </Row>
                    </Container>
                </div>
            }

            <Breadcrumb className="breadcrumb-panel">
                <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>

                {
                    getpeoplelist != null?
                    <Breadcrumb.Item href={global.variables.strDomainUrl + "people/" + getpeoplelist[0]?.pcategory_pagename}>{getpeoplelist[0]?.pcategory_name}</Breadcrumb.Item>
                    :
                    null
                }
                
                {
                    getpeoplelist != null?
                    <Breadcrumb.Item active>{getpeoplelist[0]?.people_name}</Breadcrumb.Item>
                    :
                    null
                }
                
            </Breadcrumb>


        </>
    );
};