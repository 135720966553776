import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../layout/header";
import { Container, Row, Col, Alert, Button } from "reactstrap";
import global from "../../global-variables";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BsChevronRight } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Metatag from "../../hooks/Metatag";
import WOW from "wowjs";
export default function AcademicsDetails() {
  const navigate = useNavigate();

  //Getting id from url
  let { page_name } = useParams();
  const qs_acad_pagename = page_name;

  const [academics, setAcademicsList] = useState([]);

  const [get_acad_course_name, set_acad_course_name] = useState("");

  useEffect(() => {
    let _obj = new Object();
    _obj.acad_pagename = qs_acad_pagename;

    axios
      .post(global.variables.strApiUrl + "/acadApi/getacadList", _obj)
      .then((response_list) => {

        if(response_list.data === "error")
        {
          navigate("/error-page")
        }
        else if(response_list.data[0].length === 0)
        {
          navigate("/page-not-found")
        }
        else
        {
          // pass the result in the success function of the respective page
          setAcademicsList(response_list.data[0]);
          set_acad_course_name(response_list.data[0][0].acad_course_name);
        }

      });
  }, [qs_acad_pagename]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  useEffect(() => {
    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();
  }, [qs_acad_pagename]);

  return (
    <>
      <Header main_class="bg-header-home"></Header>
      {academics[0] != undefined ? (
        <Metatag
          title={academics[0]?.acad_pagetitle}
          keywords={academics[0]?.acad_meta_keywords}
          description={academics[0]?.acad_meta_description}
        />
      ) : null}
      {/* Visual Container */}
      <div className="container-fluid visual-container-1920 px-0">
        <div
          className="bg-visual-inside position-relative"
          style={{
            backgroundImage: "url('.././images/masthead-academics.jpg')",
          }}
        >
          <img
            src={global.variables.strDomainUrl + "images/masthead-spacer.png"}
            className="img-fluid"
            alt=""
            title=""
          />
          <Container className="visual-text mx-auto">
            <h1 className="fw-bold">Academics</h1>
          </Container>
        </div>
      </div>

      {/* Body Panel */}
      <div className="container-fluid px-0 my-xl-5 my-4 editor">
        <Container className="py-md-5">
          <div className="border-1 border-primary">
            {academics.length > 0 ? (
              <div className="h-100 py-md-5">
                <div className="d-flex flex-column h-100 z-index-2 position-relative admin-contanier">
                  {academics.map((val) => {
                    return (
                      <div className="px-md-5">
                        <div>
                          
                          <div className="hd mb-3 d-block">
                            <h2 className="fw-bolder h1 m-0 wow fadeInUp">
                              {val.acad_course_name}
                            </h2>
                          </div>
                          {
                            val.acad_image !== null && val.acad_image !== "" ?
                            <img
                              src={
                                global.variables.strApiUrl +
                                "/academic-image/" +
                                val.acad_image
                              }
                              className="img-fluid float-md-end ps-lg-3 pb-3 wow fadeIn"
                              alt={val.acad_pagetitle}
                              title={val.acad_pagetitle}
                            />
                            :
                            null
                          }
                          {val.acad_brief != null && val.acad_brief != "" ? (
                            <p className="wow fadeInUp">{val.acad_brief}</p>
                          ) : null}
                          {val.acad_course_info != null &&
                          val.acad_course_info != "" ? (
                            <span
                              className="tbl_acad_course_info "
                              dangerouslySetInnerHTML={{
                                __html: val.acad_course_info,
                              }}
                            ></span>
                          ) : null}
                          {/* pdf */}
                          {val.acad_pdf != null && val.acad_pdf != "" && (
                            <a
                              target="_blank"
                              href={
                                global.variables.strApiUrl +
                                "/academic-document/" +
                                val.acad_pdf
                              }
                              className="wow btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal"
                            >
                              Download brochure
                            </a>
                          )}
                        </div>
                        <div className="float-end">
                          <Button
                            onClick={() => navigate(-1)}
                            className="btn btn-primary px-md-4 py-md-3 px-3 py-2 fw-light text-uppercase rounded-0 fw-normal ms-2"
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="h-100 py-md-5">
                <h5 className="text-center">Loading ....</h5>
              </div>
            )}
          </div>
        </Container>
      </div>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item href={global.variables.strDomainUrl + "academics"}>
          Academics
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{get_acad_course_name}</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}
