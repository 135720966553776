import { NewsApiUrls , NewsOnHomePageApiUrls} from "./api-urls";
import axios from "axios";
import globalVariables from "../global-variables";


export const GetNewsOnHomePageList = (p_success_function,navigate) => {
	//Get visual panel List
	axios.get(globalVariables.variables.strApiUrl + NewsOnHomePageApiUrls.get_news_homepage_api)
	.then((response_list) => {

		
		if(response_list.data === "error")
		{
			navigate("/error-page")
		}
		else
		{
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
	});
};

export const GetNewsList = (p_request_object, p_success_function,navigate) => {
    //Get visual panel List
	axios.post(globalVariables.variables.strApiUrl + NewsApiUrls.get_news_api, p_request_object)
	.then((response_list) => {
		if(response_list.data === "error")
		{
			navigate("/error-page")
		}
		else
		{
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
	});

};

export const GetAdmissionList = (p_request_object, p_success_function,navigate) => {
    //Get visual panel List
	axios.post(globalVariables.variables.strApiUrl + NewsApiUrls.get_admission_on_home_api, p_request_object)
	.then((response_list) => {
		if(response_list.data === "error")
		{
			navigate("/error-page")
		}
		else
		{
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
	});

};